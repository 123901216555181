import gql from 'graphql-tag'


const detailResponse = `
  id type name
  qty price totalPrice
  withholdingType withholdingTopic
  withholdingName withholdingRemark
  withholdingPrice
  unitId unit {id name}
`

export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}Order (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const WATCH_ORDER_CREATED = (templateType) => gql`subscription WATCH_ORDER_CREATED ($docType: String!, $docId: Int!) {
  orderCreated: watchDoc${templateType}OrderCreated (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const WATCH_ORDER_UPDATED = (templateType) => gql`subscription WATCH_ORDER_UPDATED ($docType: String!, $docId: Int!) {
  orderUpdated: watchDoc${templateType}OrderUpdated (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const WATCH_ORDER_DESTROYED = (templateType) => gql`subscription WATCH_ORDER_DESTROYED ($docType: String!, $docId: Int!) {
  orderDestroyed: watchDoc${templateType}OrderDestroyed (docType: $docType, docId: $docId) {
    id docId
  }
}`

export const CREATE_ORDER_WITHHOLDING = (templateType) => gql`mutation CREATE_ORDER_WITHHOLDING ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderWithholdingInput!) {
  order: createDoc${templateType}OrderWithholding (docType: $docType, docId: $docId, input: $input) {
    id docId
  }
}`

export const CREATE_ORDER_SOCIAL_SECURITY = (templateType) => gql`mutation CREATE_ORDER_SOCIAL_SECURITY ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderSocialSecurityInput!) {
  order: createDoc${templateType}OrderSocialSecurity (docType: $docType, docId: $docId, input: $input) {
    id docId
  }
}`

export const DESTROY_ORDER = (templateType) => gql`mutation DESTROY_ORDER ($docType: String!, $orderId: Int!) {
  order: destroyDoc${templateType}Order (docType: $docType, orderId: $orderId) {id docId}
}`